
import { Component, Vue } from 'vue-property-decorator';
import { Store } from 'vuex';
import { IEquipment } from '@/interfaces';
import { readEquipments } from '@/store/equipments/getters';
import { dispatchGetEquipments } from '@/store/equipments/actions';

@Component
export default class Equipments extends Vue {
  public rppi = [10, 25, { text: '$vuetify.dataIterator.rowsPerPageAll', value: -1 }];

  public headers = [
    {
      text: this.$root.$i18n.t('equip.equip_name'),
      sortable: true,
      value: 'type',
      align: 'left',
    },
    {
      text: this.$root.$i18n.t('equip.equip_station'),
      sortable: true,
      value: 'station.location',
      align: 'left',
    },
    {
      text: 'Terminal',
      sortable: true,
      value: 'station.terminal',
      align: 'left',
    },
    {
      text: 'Tipos de dados',
      sortable: false,
      value: 'type',
      align: 'left',
    },
  ];
  get equipments() {
    return readEquipments(this.$store);
  }

  public async mounted() {
    await dispatchGetEquipments(this.$store);
  }
}
